var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "show-loading": _vm.uploading,
            "action-collapse": "",
            title: _vm.isEdit
              ? _vm.$t("Nota Fiscal Importada") +
                " - Pedido #" +
                _vm.purchaseOrder.id
              : _vm.$t("Importar Nota Fiscal"),
          },
        },
        [
          _c(
            "FormulateForm",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.uploading,
                  expression: "!uploading",
                },
              ],
              ref: "form",
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _vm.isEdit
                        ? _c("FormulateInput", {
                            attrs: {
                              id: "file-name",
                              value: _vm.fileName,
                              type: "label",
                              label: _vm.$t("XML da Nota Fiscal"),
                            },
                          })
                        : _c("FormulateInput", {
                            ref: "xmlFile",
                            staticClass: "required",
                            attrs: {
                              id: "invoice-xml-file",
                              type: "uploader",
                              "max-files": "1",
                              theme: "list",
                              deletable: !_vm.isEdit,
                              meta: true,
                              accept: ".xml",
                              label: _vm.$t("XML da Nota Fiscal"),
                              validation: "required",
                              "help-text": _vm.$t(
                                "Arraste o XML da sua Nota Fiscal aqui (ou clique para selecionar o arquivo)"
                              ),
                              "delete-confirm-message": _vm.$t(
                                "A remoção do arquivo fará com que a edição atual do Pedido seja descartada. Deseja continuar?"
                              ),
                            },
                            on: {
                              input: _vm.uploadFile,
                              delete: _vm.removeFiles,
                            },
                            model: {
                              value: _vm.invoiceXmlFile,
                              callback: function ($$v) {
                                _vm.invoiceXmlFile = $$v
                              },
                              expression: "invoiceXmlFile",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm.purchaseOrder.stores && _vm.purchaseOrder.store
                    ? _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _vm.purchaseOrder.status === "Completed"
                            ? _c("FormulateInput", {
                                attrs: {
                                  id: "store-label",
                                  type: "label",
                                  label: _vm.$t("Loja"),
                                },
                                model: {
                                  value: _vm.storeLabel,
                                  callback: function ($$v) {
                                    _vm.storeLabel = $$v
                                  },
                                  expression: "storeLabel",
                                },
                              })
                            : _c("e-store-combo", {
                                attrs: {
                                  id: "store-combo",
                                  name: "storeId",
                                  required: "",
                                  filter: function (store) {
                                    return (
                                      _vm.purchaseOrder.stores
                                        .map(function (st) {
                                          return st.id
                                        })
                                        .indexOf(store.id) >= 0
                                    )
                                  },
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.purchaseOrder.store.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.purchaseOrder.store, "id", $$v)
                                  },
                                  expression: "purchaseOrder.store.id",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEdit
                    ? _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "status-label",
                              value: _vm.purchaseOrderStatus,
                              type: "label",
                              label: _vm.$t("Status"),
                              "router-to": _vm.approvalFlowRoute,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.purchaseOrder.loaded
            ? [
                _c(
                  "b-row",
                  [
                    _vm.purchaseOrder.invoiceIssuerCancelledEvent
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "b-card-actions",
                              {
                                attrs: {
                                  "action-collapse": "",
                                  title: _vm.$t(
                                    "Dados de Cancelamento da Nota Fiscal do Fornecedor"
                                  ),
                                },
                              },
                              [
                                _c("cancelled-data", {
                                  attrs: {
                                    "purchase-order": _vm.purchaseOrder,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c(
                          "b-card-actions",
                          {
                            staticClass: "h-345",
                            attrs: {
                              "show-loading": _vm.saving,
                              "action-collapse": "",
                              title: _vm.$t("Dados da Nota Fiscal"),
                            },
                          },
                          [
                            _c("invoice-form", {
                              ref: "invoiceForm",
                              attrs: {
                                "purchase-order": _vm.purchaseOrder,
                                "is-read-only": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c(
                          "b-card-actions",
                          {
                            staticClass: "h-345",
                            attrs: {
                              "show-loading": _vm.saving,
                              "action-collapse": "",
                              title: _vm.$t("Fornecedor"),
                            },
                          },
                          [
                            _c("supplier-form", {
                              ref: "supplierForm",
                              attrs: {
                                "purchase-order": _vm.purchaseOrder,
                                "is-read-only": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c(
                          "b-card-actions",
                          {
                            attrs: {
                              "show-loading": _vm.saving,
                              "action-collapse": "",
                              title: _vm.$t("Itens do Pedido"),
                            },
                          },
                          [
                            _c("order-items", {
                              ref: "orderItems",
                              attrs: {
                                "is-xml": "",
                                "purchase-order": _vm.purchaseOrder,
                              },
                              on: {
                                "update-average-prices":
                                  _vm.onUpdateItemsAveragePrices,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c(
                          "b-card-actions",
                          {
                            attrs: {
                              "show-loading": _vm.saving,
                              "action-collapse": "",
                              title: _vm.$t("Pagamentos"),
                            },
                          },
                          [
                            _c("payments", {
                              ref: "paymentsTable",
                              attrs: {
                                "purchase-order": _vm.purchaseOrder,
                                "is-xml": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("e-crud-actions", {
        attrs: {
          "show-save": false,
          busy: _vm.saving,
          "additional-actions": _vm.additionalActions,
        },
        on: {
          cancel: _vm.onCancelSubmit,
          reevaluate: _vm.onSubmitOrder,
          submit: _vm.onSubmitOrder,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }